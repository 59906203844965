import React, { useState, useEffect } from 'react';
import UnitModal from './UnitModal';
import unitsData from '../data/unitsData';
import './NewHome.css';
import { ReactComponent as MapaObras } from '../assets/svgs/obras.svg';

const NewHome = () => {
  const [activeUnit, setActiveUnit] = useState(null);

  // Lida com cliques no menu
  const handleUnitClick = (unitId) => {
    setActiveUnit(unitId);
  };

  // Lida com cliques no mapa
  const handleMapClick = (e) => {
    const clickedElement = e.target.closest('[id^="uni_"]');
    if (clickedElement) {
      const unitId = clickedElement.id.replace('uni_', '');

      // Adiciona a classe .clicked temporariamente
      clickedElement.classList.add('clicked');
      setTimeout(() => clickedElement.classList.remove('clicked'), 500); // Remove após 500ms

      setActiveUnit(unitId);
    }
  };

  // Gerencia a aplicação da classe .active nos elementos do mapa e menu
  useEffect(() => {
    // Remove a classe .active de todos os elementos do mapa e do menu
    document.querySelectorAll('.svg-unit, .left-menu button').forEach((element) => {
      element.classList.remove('active');
    });

    // Adiciona a classe .active ao elemento correspondente do mapa
    if (activeUnit) {
      const svgElement = document.getElementById(`uni_${activeUnit}`);
      if (svgElement) {
        svgElement.classList.add('active');
      }

      // Adiciona a classe .active ao botão correspondente do menu
      const activeButton = document.querySelector(`.left-menu button[data-id="${activeUnit}"]`);
      if (activeButton) {
        activeButton.classList.add('active');
      }
    }
  }, [activeUnit]);

  return (
    <div className="new-home">
      {/* Menu Lateral */}
      <div className="left-menu">
        <h3 className="tit-menu">Unidades</h3>
        <ul>
          {unitsData.map((unit) => (
            <li key={unit.id}>
              <button
                data-id={unit.id} // Garante que o botão do menu seja identificável
                onClick={() => handleUnitClick(unit.id)}
              >
                {unit.title}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Conteúdo Principal com o Mapa */}
      <div className="main-content">
        <MapaObras
          className="svg-map"
          style={{
            width: '100%',
            height: '100%',
          }}
          onClick={handleMapClick} // Escuta cliques no mapa
        />
      </div>

      {/* Modal */}
      {activeUnit && (
        <UnitModal
          unit={unitsData.find((unit) => unit.id === activeUnit)}
          onClose={() => setActiveUnit(null)}
        />
      )}
    </div>
  );
};

export default NewHome;
