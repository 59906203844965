// timelineData.js

const data = [
    {
      id: '1914',
      date: '1914',
      title: 'Nascimento',
      description: 'No dia 26 de maio nasce Maria Rita de Souza Brito Lopes Pontes, à rua São José de Baixo, 36, Barbalho, em Salvador-BA. Filha do cirurgião dentista Dr. Augusto Lopes Pontes e D. Dulce Maria de Souza Brito Lopes Pontes. No dia 13 de dezembro foi batizada na igreja de Santo Antônio Além do Carmo.',
      mediaType: 'image',
      eventType: 'birth',
      mediaSrc: '/img/1914.jpg',
      extraImageSrc: '/img/1914b.jpg' // Imagem extra
    },
    {
      id: '1927',
      date: '1927',
      title: 'Vida Religiosa',
      description: 'Manifesta pela 1ª vez o interesse pela vida religiosa. Por esta época já atendia doentes no portão de sua casa, no bairro de Nazaré.',
      mediaType: 'image',
      eventType: 'religious',
      mediaSrc: '/img/1927.jpg',
      extraImageSrc: '' // Imagem extra
    },
    {
      id: '1929',
      date: '1929',
      title: 'Curso de professora',
      description: 'Foi matriculada na Escola Normal da Bahia, no 1º ano do curso de professora.',
      mediaType: 'image',
      eventType: 'edu',
      mediaSrc: '/img/1929.jpg',
      extraImageSrc: '/img/1929b.jpg' // Imagem extra
    },
    {
      id: '1933',
      date: '1933',
      title: 'Ingresso na Vida Religiosa',
      description: 'No dia 09 de fevereiro Ingressa na Congregação das Irmãs Missionárias da Imaculada Conceição da Mãe de Deus, no Convento de N. Sra. do Carmo, em São Cristóvão-SE. No dia 13 de agosto acontece a cerimônia do noviciado, quando veste o hábito de freira e em homenagem à mãe, recebe o nome de Irmã Dulce.',
      eventType: 'religious',
      mediaType: 'image',
      mediaSrc: '/img/1933.jpg',
      extraImageSrc: '/img/1933b.jpg' // Imagem extra
    },
    {
      id: '1934',
      date: '1934',
      title: 'Missão no Hospital Espanhol',
      description: 'No mês de setembro retornou a Salvador para trabalhar na abertura do Hospital Espanhol, nas funções de enfermeira, sacristã, porteira e responsável pelo raio X.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1934.jpg',
      extraImageSrc: '/img/1934b.jpg' // Imagem extra
    },
    {
      id: '1935',
      date: '1935',
      title: 'Atendimento comunitário',
      description: 'Começa a trabalhar com os operários da Península Itapagipana e inicia também a assistência à comunidade carente dos Alagados.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1935.jpg',
      extraImageSrc: '/img/1935b.jpg' // Imagem extra
    },
    {
      id: '1936',
      date: '1936',
      title: 'União Operária São Francisco',
      description: 'No dia 1º de novembro funda a União Operária São Francisco, 1ª organização operária católica da Bahia. Em 1937 a União Operária São Francisco se transforma no Círculo Operário da Bahia.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/1936.jpg',
      extraImageSrc: '/img/1936b.jpg' // Imagem extra
    },
    {
      id: '1939',
      date: '1939',
      title: 'Inauguração do Colégio Santo Antônio',
      description: 'Inaugura o Colégio Santo Antônio, no bairro de Massaranduba, para os operários e os seus filhos. Foi também quando ocorreu a invasão das casas na rua Ilha dos Ratos, para acolher pessoas em vulnerabilidade social.',
      eventType: 'edu',
      mediaType: 'image',
      mediaSrc: '/img/1939.jpg',
      extraImageSrc: '/img/1939b.jpg' // Imagem extra
    },
    {
      id: '1948',
      date: '1948',
      title: 'Inauguração do Cine Roma',
      description: ' No dia 28 de novembro inaugura o Cine Roma, na sede do Círculo Operário da Bahia.',
      eventType: 'edu',
      mediaType: 'image',
      mediaSrc: '/img/1948.jpg',
      extraImageSrc: '/img/1948b.jpg' // Imagem extra
    },
    {
      id: '1949',
      date: '1949',
      title: 'Ocupa o galinheiro do Convento Santo Antônio',
      description: 'Ocupa o galinheiro localizado no Convento Santo Antônio após a autorização da madre Superiora, com os primeiros 70 doentes, dando origem a tradição propagada pelo povo baiano, de que o Anjo Bom construiu o maior hospital da Bahia, a partir de um simples galinheiro.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1949.jpg',
      extraImageSrc: '/img/1949b.jpg' // Imagem extra
    },
    {
      id: '1950',
      date: '1950',
      title: 'Atendimento aos presos',
      description: 'Inicia o atendimento aos presos da cadeia conhecida como “Coréia”, devido as más condições de higiene local, no Dendezeiros.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1950.jpg',
      extraImageSrc: '/img/1950b.jpg' // Imagem extra
    },
    {
      id: '1959',
      date: '1959',
      title: 'Funda a Associação Obras Sociais Irmã Dulce',
      description: 'Com o apoio  do povo baiano e de conselheiros, Santa Dulce fundou a Associação Obras Sociais Irmã Dulce.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1959.jpg',
      extraImageSrc: '/img/1959b.jpg' // Imagem extra
    },
    {
      id: '1960',
      date: '1960',
      title: 'Inaugura o Albergue Santo Antônio',
      description: 'Inaugura o Albergue Santo Antônio, com 150 leitos.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1960.jpg',
      extraImageSrc: '/img/1960b.jpg' // Imagem extra
    },
    {
      id: '1964',
      date: '1964',
      title: 'Fundação do Centro Educacional Santo Antônio',
      description: 'Inaugura o Centro Educacional Santo Antônio para acolher meninos em situação de risco social.',
      eventType: 'edu',
      mediaType: 'image',
      mediaSrc: '/img/1964.jpg',
      extraImageSrc: '/img/1964b.jpg' // Imagem extra
    },
    {
      id: '1970',
      date: '1970',
      title: 'Novo prédio do Hospital Santo Antônio',
      description: 'Inaugura novo prédio do Hospital Santo Antônio.',
      eventType: 'edu',
      mediaType: 'image',
      mediaSrc: '/img/1970.jpg',
      extraImageSrc: '/img/1970b.jpg' // Imagem extra
    },
    {
      id: '1980',
      date: '1980',
      title: 'Encontro com o Papa João Paulo II',
      description: 'Ocorreu o primeiro encontro com o Papa João Paulo II, no aeroporto de Salvador.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/1980.jpg',
      extraImageSrc: '/img/1980b.jpg' // Imagem extra
    },
    {
      id: '1983',
      date: '1983',
      title: 'Ampliação do novo Hospital Santo Antônio',
      description: 'Inaugura a ampliação do novo Hospital Santo Antônio, chegando a 1000 leitos.',
      eventType: 'hospital',
      mediaType: 'image',
      mediaSrc: '/img/1983.jpg',
      extraImageSrc: '/img/1983b.jpg' // Imagem extra
    },
    {
      id: '1988',
      date: '1988',
      title: 'Indicação ao Prêmio Nobel da Paz',
      description: 'O então Presidente da República José Sarney, indica Santa Dulce ao Prêmio Nobel da Paz, com o apoio da Rainha Sílvia da Suécia.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/1988.jpg',
      extraImageSrc: '/img/1988b.jpg' // Imagem extra
    },
    {
      id: '1991',
      date: '1991',
      title: 'Visita do Papa João Paulo II',
      description: 'Recebe pela segunda vez, no seu leito de enferma, a visita do Papa João Paulo II, (São João Paulo II) pela última vez.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/1991.jpg',
      extraImageSrc: '/img/1991b.jpg' // Imagem extra
    },
    {
      id: '1992',
      date: '1992',
      title: 'Falecimento',
      description: 'Faleceu no dia 13 de março, numa sexta-feira, às 16:45, aos 77 anos, no Convento Santo Antônio. As 20:00h, do dia 15 de março, foi sepultada no altar do Santo Cristo, na Basílica de Nossa Senhora da Conceição da Praia, na Cidade Baixa, em Salvador, Bahia, Brasil.',
      eventType: 'religious',
      mediaType: 'image',
      mediaSrc: '/img/1992.jpg',
      extraImageSrc: '/img/1992b.jpg' // Imagem extra
    },
    {
      id: '2000',
      date: '2000',
      title: 'Inicia Canonização',
      description: 'No dia 17 de janeiro ocorreu a abertura do Processo de Beatificação e Canonização de Irmã Dulce.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/2000.jpg',
      extraImageSrc: '/img/2000b.jpg' // Imagem extra
    },
    {
      id: '2011',
      date: '2011',
      title: 'Cerimônia da Beatificação',
      description: 'No dia 22 de maio acontece a cerimônia da Beatificação, quando a religiosa recebeu o título de Bem-aventurada Dulce dos Pobres. Evento realizado no Parque de Exposições Agropecuárias de Salvador, presidida por Dom. Geraldo Majella.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/2011.jpg',
      extraImageSrc: '/img/2011b.jpg' // Imagem extra
    },
    {
      id: '2019',
      date: '2019',
      title: 'Canonizada',
      description: 'No dia 13 de outubro foi canonizada pelo papa Francisco, no Vaticano, quando recebeu o título de Santa Dulce dos Pobres.',
      eventType: 'award',
      mediaType: 'image',
      mediaSrc: '/img/2019.jpg',
      extraImageSrc: '/img/2019b.jpg' // Imagem extra
    },
  ];
  
  export default data;
  