// src/components/Videos.js

import React, { useState } from 'react';
import './videos.css';
import videoList from './videoList'; // Ajuste o caminho conforme a estrutura do projeto

const Videos = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoSelect = (video) => {
    console.log('Vídeo selecionado:', video); // Para depuração
    setSelectedVideo(video);
  };

  return (
    <div className="videos-page">
      <aside className="videos-menu">
        <h2>Galeria de Vídeos</h2>
        <ul>
          {videoList.map((video, index) => (
            <li key={index} onClick={() => handleVideoSelect(video)}>
              {video.title}
            </li>
          ))}
        </ul>
      </aside>
      <main className="videos-content">
        {selectedVideo ? (
          <div className="video-player">
            <h3>{selectedVideo.title}</h3>
            <video
              key={selectedVideo.url} // Força a re-renderização
              controls
              className="video-element"
            >
              <source src={selectedVideo.url} type="video/mp4" />
              Seu navegador não suporta a reprodução de vídeo.
            </video>
          </div>
        ) : (
          <p>Selecione um vídeo da galeria.</p>
        )}
      </main>
    </div>
  );
};

export default Videos;
