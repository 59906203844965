// App.js - Integração de nova home com o projeto existente
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Timeline from './components/Timeline';
import Map from './components/Map';
import styled from 'styled-components';
import NewHome from './components/NewHome'; // Nova Home
import Videos from './components/Videos'; // Certifique-se de que o caminho está correto

const App = () => {
  return (
    <Router>
      <MainContent>
        <Routes>
          <Route path="/" element={<NewHome />} /> {/* Nova Home */}
          <Route path="/map" element={<Map />} /> {/* Mapa */}
          <Route path="/timeline" element={<Timeline />} /> {/* Linha do Tempo */}
          <Route path="/videos" element={<Videos />} /> {/* Vídeos */}
        </Routes>
      </MainContent>

      <img src="/img/logo-oside.jpg" alt="Logo OSID" className="floating-logo" />

      <MainMenu className="main-menu">
        <NavItem>
          <StyledLink to="/">Em Salvador</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/map">Na Bahia</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/timeline">Linha do Tempo</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/videos">Vídeos</StyledLink>
        </NavItem>
      </MainMenu>
    </Router>
  );
};

export default App;

const MainContent = styled.div`
  padding-bottom: 60px;
`;

const MainMenu = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
    background: linear-gradient(
    to bottom,
    rgba(0, 23, 60, 0.95),
    rgba(8, 55, 131, 0.95)
  );
    border-top: 1px solid #CAA05F;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 20px 0 20px 0;
  z-index: 1000;
`;

const NavItem = styled.div`
  font-size: 1.2rem;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover {
    color: #ddd;
  }
`;
