// data/unitsData.js
const unitsData = [
  {
    id: 'santuario',
    title: 'Complexo Santuário',
    image: '/assets/unid/santuario.jpg',
    description: 'Local de acolhimento aos visitantes que vêm em busca de suporte espiritual e para conhecer a história da vida e obra de Santa Dulce dos Pobres. Compõe o Complexo Santuário: o Santuário Arquidiocesano de Santa Dulce dos Pobres, Memorial Santa Dulce dos Pobres, Loja Santa Dulce o Dulce Café e a Coordenação de Turismo Religioso.',
  },
  {
    id: 'galinheiro',
    title: 'Galinheiro',
    image: '/assets/unid/galinheiro.jpg',
    description: 'Espaço simbólico onde, em 1949, Santa Dulce dos Pobres passou a acolher pobres e doentes em situação de vulnerabilidade. Considerado o marco zero das Obras Sociais Irmã Dulce, representando solidariedade e compaixão.',
  },
  {
    id: 'crianca',
    title: 'Hospital da Criança',
    image: '/assets/unid/crianca.jpg',
    description: 'Inaugurado em dezembro de 2000, o Hospital da Criança oferece assistência médico-hospitalar humanizada, seguindo os princípios defendidos por Santa Dulce – prestando atendimento integral ao paciente. O HC também possui programas sócio-educativos, como o projeto Escola no Hospital, o programa de Combate aos Maus Tratos e o Clube de Mães.',
  },
  {
    id: 'antonio',
    title: 'Hospital Santo Antônio',
    image: '/assets/unid/antonio.jpg',
    description: 'Unidade de referência em assistência hospitalar, com equipes multidisciplinares e cuidados intensivos. Destaca-se pelo acolhimento humanizado e pelo trabalho social.',
  },
  {
    id: 'sarney',
    title: 'Ambulatório José Sarney',
    image: '/assets/unid/sarney.jpg',
    description: 'Principal porta de entrada das Obras Sociais Irmã Dulce. Recebe diariamente milhares de pessoas oriundas de Salvador e do interior do estado, em busca de acolhida e atendimento digno em Saúde. A inauguração do Ambulatório, em 1986, marcou um importante capítulo na trajetória de expansão dos serviços prestados pela instituição. Realiza hoje mais de 540 mil atendimentos e procedimentos anualmente.',
  },
  {
    id: 'magalhaes',
    title: 'Centro de Geriatria e Gerontologia Júlia Magalhães',
    image: '/assets/unid/magalhaes.jpg',
    description: 'Referência no atendimento ao idoso, o CGGJM é a tradução fiel da luta de Santa Dulce por uma assistência integral ao público da melhor idade, com estímulo à cidadania, socialização e qualidade de vida. Criado em 1986, é o único complexo do estado a oferecer, ao mesmo tempo, atendimento ambulatorial e internação hospitalar para casos graves e pacientes crônicos. O CGGJM mantém a Morada de Idosos, como 54 leitos, que acolhe moradores em situação de vulnerabilidade.',
  },
  {
    id: 'unacon',
    title: 'Unidade de Alta Complexidade em Oncologia Nossa Senhora de Fátima (UNACON)',
    image: '/assets/unid/unacon.jpg',
    description: 'Inaugurada em 13 de maio de 2015, a Unidade de Alta Complexidade em Oncologia oferece gratuitamente à população tratamento completo contra o câncer, incluindo procedimentos de radioterapia e quimioterapia, consultas ambulatoriais, internamento e cirurgias no campo oncológico.',
  },
  {
    id: 'capd',
    title: 'Centro de Acolhimento à Pessoa com Deficiência João Paulo II (CAPD)',
    image: '/assets/unid/capd.jpg',
    description: 'O Centro presta assistência integral a pessoas com múltiplas deficiências. A maioria delas foi, há muitos anos, acolhida por Santa Dulce. Atualmente, 88 pessoas residentes, de ambos os sexos. Além dos cuidados básicos de moradia tem por missão a criação de um ambiente cotidiano de trocas afetivas, a construção progressiva da autonomia e o desenvolvimento de atividades que contribuam para a inclusão social e comunitária dos moradores.',
  },
  {
    id: 'cata',
    title: 'Centro de Atendimento e Tratamento de Alcoolistas (CATA) e o Centro Médico Social Augusto Lopes Pontes (CMSALP)',
    image: '/assets/unid/cata.jpg',
    description: 'O CATA: Fundado em dezembro de 1994, o centro foi a primeira unidade de saúde da Bahia dedicada exclusivamente ao atendimento de pessoas com problemas relacionados ao uso abusivo e à dependência de etílicos. É o único do gênero no estado a trabalhar com sistema de internação para alcoolistas. Presta atendimento ambulatorial e de internação de curta duração.<br>O CMSALP: É uma das alas de atendimento do Hospital Santo Antônio destinada a pacientes crônicos que necessitam de internação de longa permanência. A unidade proporciona assistência médica integral, com uma equipe multidisciplinar, composta por médicos, fonoaudióloga, assistente social, musicoterapeuta, enfermeiros, psicólogo e terapeuta ocupacional.',
  },
    {
      id: 'upa',
      title: 'UPA Santo Antonio',
      image: '/assets/unid/upa.jpg',
      description: 'Presta atendimentos de urgência e emergência 24 horas, promovendo estabilização e encaminhamento. Integra a rede de saúde de Irmã Dulce, garantindo assistência imediata.',
    },
    // Adicione outras unidades aqui
  ];
  
  export default unitsData;
  